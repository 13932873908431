<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="选择时间" prop="date">
              <el-date-picker
                v-model="form.date"
                type="datetimerange"
                @change="dateChange"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="storeId	" label="仓库">
              <el-select clearable v-model="form.storeId" style="width: 100%">
                <el-option v-for="item in wareHouseList" :key="item.id" :label="item.venueName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="type" label="类型">
              <el-select clearable v-model="form.type" style="width: 100%">
                <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="text-right">
            <el-button
              type="primary"
              @click="searchEvent"
              class="search-button"
              >{{ $t("search") }}</el-button
            >
            <el-button type="primary" @click="cleanForm" class="clean-button">{{
              $t("clean")
            }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight - 20"
        style="width: 100%"
        border
        @sort-change="sort_post_change"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" align="center" label="序号" width="55">
        </el-table-column>
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column
          prop="eventTime"
          align="left"
          min-width="220"
          label="操作时间"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="left"
          min-width="220"
          label="记录时间"
        >
        </el-table-column>
        <el-table-column
          prop="venueName"
          align="left"
          min-width="120"
          label="仓库"
        >
        </el-table-column>
        <el-table-column
          prop="productName"
          align="left"
          header-align="center"
          min-width="220"
          label="商品名称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="unitText"
          min-width="80"
          show-overflow-tooltip
          label="单位"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="typeText"
          min-width="120"
          label="类型"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="stockQty"
          min-width="120"
          label="数量"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="pageination-wrapper">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixin from "@/mixins/index";
import IndexClass from "./indexClass.js";
import apis from "@/apis";
import { changeInfo } from "@/utils/index";

export default {
  mixins: [mixin],
  components: {
  },
  data() {
    return {
      form: new IndexClass("form"),
      tableData: [],
      wareHouseList: [],
      typeList: [{
        label: '出库',
        value: 2,
      }, {
        label: '入库',
        value: 1,
      }],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
    };
  },
  computed: {
    ...mapState(["Language", "venueId", "DROPDOWNBOX"]),
  },
  methods: {
    dateChange(val) {
      if (val) {
        this.form.beginTime = this.formatDate(val[0])
        this.form.endTime = this.formatDate(val[1])
        return
      }
      this.form.beginTime = ''
      this.form.endTime = ''
    },
    formatDate(date) {
      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = date.getDate();
      d = d < 10 ? `0${d}` : d;
      let h = date.getHours();
      h = h < 10 ? `0${h}` : h;
      let minute = date.getMinutes();
      minute = minute < 10 ? `0${minute}` : minute;
      let second = date.getSeconds();
      second = second < 10 ? `0${second}` : second;
      return `${y}-${m}-${d} ${h}:${minute}:${second}`
    },
    // 获取仓库
    getWareHouse() {
      this.$http.post(apis.venuevManagementList, {}).then((res) => {
        if (res.data.code === 0) {
          this.wareHouseList = res.data.rows
        }
      })
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.venueProductStockRecord, this.form).then((res) => {
        console.log(55555)
        if (res.data.code === 0) {
          this.tableData = res.data.rows.map((item) => {
            item.typeText = changeInfo(item.type, this.typeList, 'value', 'label')
            item.unitText = changeInfo(item.unit, this.DROPDOWNBOX.PRODUCT_UNIT, 'value', 'label')
            return item
          });
          this.form.total = res.data.total;
        }
      });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new IndexClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
  },
  mounted() {
    this.getWareHouse()
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.search-wrapper {
  width: 100%;
}
.pageination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
